import { Component, OnInit } from '@angular/core';
import{AdminService} from '../admin.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormBuilder,FormGroup } from '@angular/forms';
import{Permission} from'../permission'

@Component({
  selector: 'app-add-permission',
  templateUrl: './add-permission.component.html',
  styleUrls: ['./add-permission.component.css']
})
export class AddPermissionComponent implements OnInit {
	
	users:any;
  pager: any = {};

//paged items
  pagedItems: any[];
  filteredList:any[];

  private _searchTerm: string;

  get searchTerm(): string {
    return this._searchTerm;
  }
  set searchTerm(value: string) {
    this._searchTerm = value;
    this.filteredList = this.filterList(value);
  }
  constructor(private adminservice:AdminService, private router:Router) { }

  ngOnInit() {

   this.getAllUsersData();
  }

  getAllUsersData(){
     this.adminservice.getAllUsers().subscribe(result=>{
    this.users=result.data,
     this.setPage(1);
    console.log(this.users)
    });


  }

  deleteUser(user_id:number){
    this.adminservice.userDelete(user_id).subscribe(response=>{
     if(response.status){
          alert('User Dleted ');
          this.getAllUsersData();
          this.router.navigate(['/add_permission']);
        }else{
            alert('Some technical Error!! please try again later');
             this.router.navigate(['/dashboard']);
            
        }
    });

  }

   setPage(page: number) {
        // get pager object from service
        this.pager = this.adminservice.getPager(this.users.length, page);

        // get current page of items
        this.pagedItems = this.users.slice(this.pager.startIndex, this.pager.endIndex + 1);
        this.filteredList=this.pagedItems;
    }

    filterList(searchString: string) {
    return this.users.filter(users =>
      users.user_name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1);
 }

}
