import { Component, OnInit } from '@angular/core';
import{AdminService} from '../admin.service';
import { Router, ActivatedRoute } from '@angular/router';
import {Roles} from '../roles';
import { AuthGuard } from '../auth.guard';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  baseUrl1="http://127.0.0.1:8000/uploads/";
	data:string;
	username:string;
  rolename:string;
  company_name:string;
  company_id:number;
  image:string;
  teaestate_name:string;
  roles = Roles;
  user_role:any;
  role:any;
  constructor( private adminservice: AdminService,private router:Router) { 
  		if (localStorage.getItem('currentUser')){
     
      	this.data=JSON.parse(localStorage.getItem('currentUser'));
     	  this.username=this.data['user']['userdetails']['user_name'];
        this.rolename=this.data['user']['userdetails']['role']['role_name'];
        if(this.rolename==null){
          this.rolename= "Admin";
        }
        
        if(this.data['user']['userdetails']['company']['company_name']){
          this.company_name=this.data['user']['userdetails']['company']['company_name'];
          this.company_id = this.data['user']['userdetails']['company']['id'];
        }else{
          this.company_name = "ABC Company";
        }

        
        
        if(this.data['user']['teaestate']!=null){
          this.teaestate_name =this.data['user']['teaestate']['tea_estate_name'];
          
        }else{
          this.teaestate_name = "Abc Tea Estate";
        }
       
        if(this.data['user']['userdetails']['image']){
           this.image=this.data['user']['userdetails']['image'];
        
        }else{
            this.image = "/assets/avatar.jpg";
        }
        this.user_role=this.data['user']['roles'];
       
  	}


  }

  ngOnInit() {}
   get isLoggedIn() { 
    return this.adminservice.isLoggedIn();
  }

  
  isPermitted(role:string){
     for(var i= 0 ; i< this.user_role.length;i++){
          this.role=this.user_role[i];

          if(this.role===role){
           
           return true;
           }
        }

    
   
  }

  

  }

