import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import{AdminService} from '../admin.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private adminservice: AdminService, private router:Router) { }
  	users:[];
  ngOnInit() {
   this.tokenCheck();
  }

  tokenCheck(){
  	this.adminservice.tokenCheck().subscribe((response)=>{
  		if(response){
  			this.router.navigate(['/dashboard']);
  		}
  	},(error)=>{
  		if(error.status==401){
        
        this.adminservice.logout();
        this.router.navigate(["/login"])
        }
  	});
  }
  
}
