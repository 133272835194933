import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup,FormArray,FormControl} from '@angular/forms';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import{AdminService} from '../admin.service';
import { NgForm }   from '@angular/forms';
import{User}from'../user'

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

 
  countries:{};
  states: {};
  cities: {};
  companies:{};
  teaestates:{};
  userdata:any;
  user= new User();
  roles:{};
  notFocused = false;
  errorMessage = new User();
  constructor(private adminservice: AdminService,private router:Router) { 
  		 this.userdata=JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {
  	this.getCompanies();
  	this.getRoles();
  	this.getCountries();

  }

/* GET COMAPNIES */
getCompanies(){
	this.adminservice.getComapnies().subscribe(response=>{
			this.companies=response.data
		});
}
/*GET Tea Estate */
 getTeaestate(id:number){
  this.adminservice.getTeaestatesaByCompanyId(id).subscribe((response)=>{
    this.teaestates=response.msg[0]['company_wise_teaestate'];
  })

 }
/* GET Roles */
  getRoles(){
    this.adminservice.getRoles().subscribe(response=>{
     this.roles=response.data,
     console.log(this.roles)
    });

  }
  /******************************/

  /* GET COUNTRIES */
  getCountries(){

  this.adminservice.getCountries().subscribe(
      response => {this.countries = response.data
   });
  }

  /*******************************/

  onChangeCountry(countryId: number) {
    if (countryId) {
      this.adminservice.getStates(countryId).subscribe(
        response => {
          this.states = response.data;
          this.cities = null;
        }
      );
    } else {
      this.states = null;
      this.cities = null;
    }
  }

  onChangeState(stateId: number) {
    if (stateId) {
      this.adminservice.getCities(stateId).subscribe(
        response =>{ this.cities = response.data
      });
    } else {
      this.cities = null;
    }
  }

  /* CONVERT IMAGE TO BTOA STRING */
  private base64textString:string="";
  
  handleFileSelect(evt){
      var files = evt.target.files;
      var file = files[0];
    
    if (files && file) {
        var reader = new FileReader();

        reader.onload =this._handleReaderLoaded.bind(this);

        reader.readAsBinaryString(file);
    }
  }
  
  _handleReaderLoaded(readerEvt) {
     var binaryString = readerEvt.target.result;
            this.base64textString= btoa(binaryString);
            this.user.image=this.base64textString;
           
   }
 /***********************************/

  /* FUNCTION TO  Register USER PERSONAL DATA*/
submit(form: NgForm){
	this.user.created_by=this.userdata['user']['userdetails']['id'];
	this.adminservice.userResgistration(this.user).subscribe(response=>{
    if(response.status){
      Swal.fire( 'User Added', 'success');
       form.reset();
      this.router.navigate(['/user_list']);
    }else{
        Swal.fire('Oops...', 'Something went wrong!', 'error');
         this.router.navigate(['/user_list']);
   }  
},(error)=>{
    if(error.status==401){
      this.errorMessage=error.message;
      this.adminservice.logout();
      this.router.navigate(["/login"])
      }else{
        this.errorMessage=error.error.error;
       console.log(this.errorMessage.user_name);
      }
    
    }); 
	

}

/***********************************/


}
