import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup,FormArray,FormControl} from '@angular/forms';
import { first } from 'rxjs/operators';
import{AdminService} from '../admin.service';
import { NgForm }   from '@angular/forms';
import{User} from'../user'
import{Module}from'./module'

@Component({
  selector: 'app-user-permission',
  templateUrl: './user-permission.component.html',
  styleUrls: ['./user-permission.component.css']
})
export class UserPermissionComponent implements OnInit {

  masterSelected:boolean;
  ActionSelected:boolean;

  checklist:any;
  checkedList:any;
  user= new User();
  permissions:any;
  allPermissions:any;
  userdata:any;
  data1:any;
  roles:any;
  id:number;
  countries:{};
  states: {};
  cities: {};
  constructor(private adminservice: AdminService, private router:ActivatedRoute, private route:Router) { 
      this.masterSelected = false;
      this.ActionSelected=false;
      this.userdata=JSON.parse(localStorage.getItem('currentUser'));

  }

  ngOnInit() {
    this.id=this.router.snapshot.params.id;
  
    this.getUserById(this.id);
    this.getRoles();
  	this.permissionListWithUserDatat();
    this.getCountries();

    
  }


/* GET Roles */
  getRoles(){
    this.adminservice.getRoles().subscribe(response=>{
     this.roles=response.data
    
    
    });

  }
  /******************************/

  /* LIST OF MODULE CONTROLLER AND ACTION */

   permissionListWithUserDatat(){

    this.adminservice.permission().subscribe(response=>{
    
    this.checklist=response.message    
    });
   }
  /***********************************/

/* GET USER BY ID */
  getUserById(id:number){
    this.adminservice.getUserById(id).subscribe(response=>{
     this.user=response.data[0]
    });

  }
  /******************************/
   /* GET COUNTRIES */
  getCountries(){

  this.adminservice.getCountries().subscribe(
      response => {this.countries = response.data
   });
  }s

  /*******************************/

  onChangeCountry(countryId: number) {
    if (countryId) {
      this.adminservice.getStates(countryId).subscribe(
        response => {
          this.states = response.data;
          this.cities = null;
        }
      );
    } else {
      this.states = null;
      this.cities = null;
    }
  }

  onChangeState(stateId: number) {
    if (stateId) {
      this.adminservice.getCities(stateId).subscribe(
        response =>{ this.cities = response.data
      });
    } else {
      this.cities = null;
    }
  }

  

/* CONVERT IMAGE TO BTOA STRING */
  private base64textString:string="";
  
  handleFileSelect(evt){
      var files = evt.target.files;
      var file = files[0];
    
    if (files && file) {
        var reader = new FileReader();

        reader.onload =this._handleReaderLoaded.bind(this);

        reader.readAsBinaryString(file);
    }
  }
  
  _handleReaderLoaded(readerEvt) {
     var binaryString = readerEvt.target.result;
            this.base64textString= btoa(binaryString);
            this.user.image=this.base64textString;
            console.log(this.user.image);
    }
 /***********************************/

/* FUNCTION TO SELECT ALL PERMISSION */
  checkUncheckAll() {
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
      for(var j=0; j< this.checklist[i]['controller'].length; j++){
         this.checklist[i]['controller'][j].isSelected = this.masterSelected;
        for(var k=0; k< this.checklist[i]['controller'][j]['action'].length; k++){
         this.checklist[i]['controller'][j]['action'][k].isSelected = this.masterSelected;
        }
      }
     
  }
 this.allPermissions= this.getCheckedItemList();
 console.log(this.allPermissions);
}
 /***********************************/


/* FUNCTION TO  SELECT/DIS SELECT ALL ACTION */
 chekUncheckAllAction(){

  for (var i = 0; i < this.checklist.length; i++) {
      for(var j=0; j< this.checklist[i]['controller'].length; j++){
        for(var k=0; k< this.checklist[i]['controller'][j]['action'].length; k++){
         this.checklist[i]['controller'][j]['action'][k].isSelected = this.ActionSelected;
        }
      }
      this.permissions= this.getaction();
      console.log(this.permissions);
  }

 }
/***********************************/


/* FUNCTION TO CHECK IF ALL PERMISSION SELECT OR NOT */
  isAllSelected() {
    this.masterSelected = this.checklist.every(function(item:any) {
        return item.isSelected == true;
      })
    this.allPermissions= this.getCheckedItemList();
    console.log(this.allPermissions);
  }

  /***********************************/


/* FUNCTION TO   CHECK IF ALL CONTROLLER SELECT OR NOT*/

  isControllerSelceted(controller:any) {
     this.masterSelected = controller.forEach(function(cont) {
        return cont.isSelected == true;
      })
    
   // this.getcontroller();
  }

  /***********************************/


/* FUNCTION TO   CHECK IF ALL ACTIONS SELECT OR NOT*/
  isActionSelceted(action:any) {
     this.masterSelected = action.forEach(function(act) {
        return act.isSelected == true;
      })
   this.permissions=this.getaction();
   console.log(this.permissions);
    
  
  }


  /***********************************/


/* FUNCTION TO   CHECK ALL SELECTED ITEM*/

  getCheckedItemList(){
    this.checkedList = [];
  
  
    for (var i = 0; i < this.checklist.length; i++) {
      if(this.checklist[i].isSelected)
      this.checkedList.push(this.checklist[i]);
     }
    return this.checkedList;
  }
/***********************************/


/* FUNCTION TO   CHECK ALL SELECTED CONTROLLER ITEM*/
  getcontroller(){
    
  var checkedList1=[];
   this.checklist.forEach(function (item) {  
        item.controller.forEach(function (cont) {  
        if(cont.isSelected)
        checkedList1.push(cont);
        });  
    }); 
    console.log(checkedList1);
  }
/***********************************/


/* FUNCTION TO   CHECK ALL SELECTED ACTION ITEM*/
  getaction(){
    
  var checkedList2=[];
   this.checklist.forEach(function (item) {  
        item.controller.forEach(function (cont) {  
          cont.action.forEach(function (act) {  
          if(act.isSelected)
          checkedList2.push(act);
          });  
        });  
    }); 
    return checkedList2;
  }

/***********************************/


/* FUNCTION TO  UPDATE USER PERSONAL DATA*/
submit(){

	this.adminservice.userUpdate(this.user).subscribe(response=>{
    if(response.status){
      alert('User Updated .');
      this.route.navigate(['/dashboard']);
    }else{
        alert('Some technical Error!! please try again later');
         this.route.navigate(['/permission']);
   }  
}); 

}

/***********************************/


/* FUNCTION TO   CREATE USER PERMISSION*/

createUserpermission(form: NgForm){
  var data1=[];
  if(this.allPermissions!=null){
    for(var i=0 ;i<this.allPermissions.length;i++){
      this.allPermissions[i]['role_id']=this.user.role_id;
      this.allPermissions[i]['company_id']=this.user.company_id;
      this.allPermissions[i]['created_by']=this.userdata['user']['userdetails']['id'];
      this.allPermissions[i]['type']=1;
    }
    this.data1=this.allPermissions;
    

  }else if(this.permissions!=null){
    for(var i=0 ;i<this.permissions.length;i++){
      this.permissions[i]['role_id']=this.user.role_id;
      this.permissions[i]['company_id']=this.user.company_id;
      this.permissions[i]['created_by']=this.userdata['user']['userdetails']['id'];
      this.permissions[i]['type']=2;
    }
    this.data1=this.permissions;
   
  }else{
    alert("Please select checkbox!!")
  }
    
  this.adminservice.createRolePermission(this.data1).subscribe(response=>{
   
    if(response.status===false){
    
      alert(response.message);
    }else{
      alert("Role permission created successfully!!");
       this.data1=null;
       this.permissions=null;
       this.allPermissions=null;
    }
    
    });
  form.reset();
}

/***********************************/


/* FUNCTION TO DELETE   USER */

deleteUser(id:number){
  this.adminservice.userDelete(id).subscribe(response=>{
      if(response.status){
        alert("User Deleted !!");
        this.route.navigate(['/user_list']);
      }else{
        alert("Something went wrong!!");
      }
    })
}

/***********************************/


/*BACK TO DASHBOARD */
goBack(){
    this.route.navigate(['/dashboard']);
}
/***********************************/


}
