import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router} from '@angular/router';
import { Observable } from 'rxjs';
import{AdminService} from './admin.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  userdata:any
  constructor(
        private router: Router,
        private adminservice: AdminService
    ) { 
        this.userdata=JSON.parse(localStorage.getItem('currentUser'));
  }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):boolean{
    if (this.adminservice.isLoggedIn()) {
       if (this.userdata) {
        console.log(route.data.roles);
            // check if route is restricted by role
            if (route.data.roles && this.userdata['user']['roles'].indexOf(route.data.roles[0]) === -1) {
                // role not authorised so redirect to home page
                this.router.navigate(['/']);
                return false;
                
            }
 
            // authorised so return true
            return true;
            console.log(route.data.roles);
        }
         console.log(route.data.roles);
      return true;
    }else{
    	this.router.navigate(['/login']);
    }
  }
  
}
