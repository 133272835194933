import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { AddPermissionComponent } from "./add-permission/add-permission.component";
import { AuthGuard } from "./auth.guard";
import { UserPermissionComponent } from "./user-permission/user-permission.component";
import { PagenotfoundComponent } from "./pagenotfound/pagenotfound.component";
import { AddUserComponent } from "./add-user/add-user.component";
import { Roles } from "./roles";

const routes: Routes = [
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "add_user",
    component: AddUserComponent,
    canActivate: [AuthGuard],
    data: { roles: [Roles.UserRegistration] },
  },

  {
    path: "user_list",
    component: AddPermissionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "permission/:id",
    component: UserPermissionComponent,
    canActivate: [AuthGuard],
  },
  { path: "login", component: LoginComponent },

  {
    path: "company",
    loadChildren: () =>
      import("./company/company.module").then((m) => m.CompanyModule),
  },
  {
    path: "teaestate",
    loadChildren: () =>
      import("./teaestate/teaestate.module").then((m) => m.TeaestateModule),
  },
  {
    path: "teaestate_division",
    loadChildren: () =>
      import("./teaestatedivision/teaestatedivision.module").then(
        (m) => m.TeaestatedivisionModule
      ),
  },
  {
    path: "vendor",
    loadChildren: () =>
      import("./vendor/vendor.module").then((m) => m.VendorModule),
  },

  {
    path: "teaestate_unit",
    loadChildren: () =>
      import("./teaestateunit/teaestateunit.module").then(
        (m) => m.TeaestateunitModule
      ),
  },
  {
    path: "employee",
    loadChildren: () =>
      import("./employee/employee.module").then((m) => m.EmployeeModule),
  },
  {
    path: "inventory_item",
    loadChildren: () =>
      import("./inventory/inventory.module").then((m) => m.InventoryModule),
  },
  {
    path: "segment",
    loadChildren: () =>
      import("./segment/segment.module").then((m) => m.SegmentModule),
  },
  {
    path: "factory",
    loadChildren: () =>
      import("./factory/factory.module").then((m) => m.FactoryModule),
  },
  {
    path: "field-plantation",
    loadChildren: () =>
      import("./field-plantation/field-plantation.module").then(
        (m) => m.FieldPlantationModule
      ),
  },
  {
    path: "sponsor-management",
    loadChildren: () =>
      import("./sponsor/sponsor.module").then((m) => m.SponsorModule),
  },
  {
    path: "billing",
    loadChildren: () =>
      import("./billing/billing.module").then((m) => m.BillingModule),
  },
  {
    path: "banking",
    loadChildren: () =>
      import("./banking/banking.module").then((m) => m.BankingModule),
  },
  {
    path: "report",
    loadChildren: () =>
      import("./report/report.module").then((m) => m.ReportModule),
  },
  {
    path: "hr",
    loadChildren: () => import("./hr/hr.module").then((m) => m.HrModule),
  },
  {
    path: "user_role",
    loadChildren: () =>
      import("./users/users.module").then((m) => m.UsersModule),
  },
  {
    path: "wage_setup",
    loadChildren: () =>
      import("./wagesetup/wagesetup.module").then((m) => m.WagesetupModule),
  },
  { path: "", pathMatch: "full", redirectTo: "dashboard" },
  { path: "404", component: PagenotfoundComponent, canActivate: [AuthGuard] },
  { path: "**", redirectTo: "/404" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
