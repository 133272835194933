export class User {
    id: number;
    user_name:string;
    password:string;
    email_id:string;
    role_id:number;
    role_name: string;
    image:string;
	addressline1:string;
	addressline2:string;
	city_name:string;
	pincode:string;
	state_name:string;
	country_name:string;
	city_id:number;
	country_id:number;
	state_id:number;
	company_id:number;
	created_by:number;
	company_name:string;
	tea_estate_name:string;
	teaestate_id:string;
    
}
