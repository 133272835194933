import { Component } from '@angular/core';
import{AdminService} from './admin.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'AdminPanel';
  constructor( private adminservice: AdminService,private router:Router) { }
     
  get isLoggedIn() { return this.adminservice.isLoggedIn(); }
  get logout(){
		localStorage.removeItem('currentUser');
  		this.router.navigate(['/login']);
      return true;
  	}
}
