import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  baseUrl1= "https://app-tea.pitangent.in/"//"http://www.teaestateapi.local/"//"https://app-tea.pitangent.in/"

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): string {
    return this.currentUserSubject.value;
  }

  login(params) {
    return this.http
      .post<any>(`${this.baseUrl1}users/users/userLogin`, params)
      .pipe(
        map((user) => {
          //login successful if there's a jwt token in the response
          if (user && user.token) {
            //console.log(user);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(user));
            return user;
          } else {
            return throwError(user.message);
          }
        })
      );
  }

  tokenCheck() {
    return this.http.get<any>(`${this.baseUrl1}default/module/token`);
  }

  userResgistration(params) {
    return this.http.post<any>(
      `${this.baseUrl1}users/users/userRegistration`,
      params
    );
  }

  getAllUsers() {
    return this.http.get<any>(`${this.baseUrl1}users/users/getAllUsers`);
  }

  permission() {
    return this.http.get<any>(`${this.baseUrl1}default/module/getAllRecords`);
  }

  getUserById(id: number) {
    return this.http.post<any>(`${this.baseUrl1}users/users/getUserById`, {
      id: id,
    });
  }
  userUpdate(params) {
    return this.http.post<any>(
      `${this.baseUrl1}users/users/userUpdate`,
      params
    );
  }

  createRolePermission(params) {
    return this.http.post<any>(
      `${this.baseUrl1}users/users/createUserRolePermission`,
      params
    );
  }

  userDelete(id: number) {
    return this.http.post<any>(`${this.baseUrl1}users/users/deleteUser`, {
      id: id,
    });
  }

  getAuthorizationToken() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    return currentUser.token;
  }

  companyRegistration(params) {
    return this.http.post<any>(
      `${this.baseUrl1}company/company/companyRegistration`,
      params
    );
  }

  completeCompanyRegistration(params) {
    return this.http.post<any>(
      `${this.baseUrl1}company/company/completeCompanyRegistration`,
      params
    );
  }

  // Get companies without authentication//
  getComapnies() {
    return this.http.get<any>(`${this.baseUrl1}company/company/findCompany`);
  }
  //**********************************************/////
  getComapanyById(id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}company/company/findCompanyById`,
      { id: id }
    );
  }
  companyUpdate(params) {
    return this.http.post<any>(
      `${this.baseUrl1}company/company/updateCompany`,
      params
    );
  }

  companyDelete(id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}company/company/deleteCompany`,
      { id: id }
    );
  }

  addCompanyAccountDetails(params) {
    return this.http.post<any>(
      `${this.baseUrl1}company/company/addCompanyAccountDetails`,
      params
    );
  }

  getCompanyAccountDetails() {
    return this.http.get<any>(
      `${this.baseUrl1}company/company/getCompanyAccountDetails`
    );
  }

  getAccountDetailsById(account_id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}company/company/getAccountDetailsById`,
      { account_id: account_id }
    );
  }
  updateCompanyAccountDetails(params) {
    return this.http.post<any>(
      `${this.baseUrl1}company/company/updateCompanyAccountDetails`,
      params
    );
  }
  deleteCompanyAccount(id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}company/company/deleteCompanyAccount`,
      { id: id }
    );
  }

  addteaestate(params) {
    return this.http.post<any>(
      `${this.baseUrl1}company/teaestate/teaEstateRegistration`,
      params
    );
  }
  getteaestates() {
    return this.http.get<any>(
      `${this.baseUrl1}company/teaestate/findTeaestate`
    );
  }

  getteaestateById(id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}company/teaestate/findTeaestateById`,
      { id: id }
    );
  }

  getTeaestatesaByCompanyId(id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}company/company/findTeaestateByCompanyId`,
      { company_id: id }
    );
  }

  // Get tea estate without auth//
  getTeaestaeForUser(id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}users/users/getTeaestateByCompanyId`,
      { company_id: id }
    );
  }
  //***************************//

  teaestateUpdate(params) {
    return this.http.post<any>(
      `${this.baseUrl1}company/teaestate/updateTeaestate`,
      params
    );
  }
  teaestateDelete(id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}company/teaestate/deleteTeaestate`,
      { id: id }
    );
  }

  completeteaestateRegistration(params) {
    return this.http.post<any>(
      `${this.baseUrl1}company/teaestate/completeTeaEstateRegistration`,
      params
    );
  }

  addDivision(params) {
    return this.http.post<any>(
      `${this.baseUrl1}company/teaestatedivision/createTeaestateDivision`,
      params
    );
  }

  findDivisionByTeaestateId(tea_estate_id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}company/teaestate/findDivisionByTeaestateId`,
      { tea_estate_id: tea_estate_id }
    );
  }

  findDivisionById(division_id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}company/teaestatedivision/fetchTeaestateDivisionById`,
      { id: division_id }
    );
  }

  findDivision() {
    return this.http.get<any>(
      `${this.baseUrl1}company/teaestatedivision/fetchTeaestateDivision`
    );
  }

  updateDivision(params) {
    return this.http.post<any>(
      `${this.baseUrl1}company/teaestatedivision/updateTeaestateDivisionById`,
      params
    );
  }
  deleteDivision(division_id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}company/teaestatedivision/deleteTeaestateDivision`,
      { id: division_id }
    );
  }

  addTeaestateUnit(params) {
    return this.http.post<any>(
      `${this.baseUrl1}company/teaestateunit/createTeaestateUnit`,
      params
    );
  }

  getTeaestateUnitByDivisionId(division_id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}company/teaestatedivision/fetchTeaestateUnitByDivisionId`,
      { division_id: division_id }
    );
  }

  findTeaEstateUnitById(id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}company/teaestateunit/fetchTeaestateUnitById`,
      { id: id }
    );
  }

  getTeaestateUnitTypes() {
    return this.http.get<any>(
      `${this.baseUrl1}company/teaestateunit/fetchTeaestateUnitType`
    );
  }

  updateTeaestateUnit(params) {
    return this.http.post<any>(
      `${this.baseUrl1}company/teaestateunit/updateTeaestateUnit`,
      params
    );
  }

  addEmployee(params) {
    return this.http.post<any>(
      `${this.baseUrl1}employees/employees/employeesEntry`,
      params
    );
  }

  getEmployeeByCompanyId(id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}employees/employees/employeesList`,
      { company_id: id }
    );
  }
  getEmployeeById(id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}employees/employees/viewEmployeeById`,
      { id: id }
    );
  }

  employeeUpdate(params) {
    return this.http.post<any>(
      `${this.baseUrl1}employees/employees/employeesUpdate`,
      params
    );
  }
  employeeDelete(id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}employees/employees/employeesDelete`,
      { id: id }
    );
  }
  getEmployeeTypes() {
    return this.http.get<any>(
      `${this.baseUrl1}employees/employees/getEmployeeTypes`
    );
  }

  getEmployeeCategory() {
    return this.http.get<any>(
      `${this.baseUrl1}employees/employees/getEmployeeCategory`
    );
  }

  viewEmployeeByEmployeeType(params) {
    return this.http.post<any>(
      `${this.baseUrl1}employees/employees/viewEmployeeByEmployeeType`,
      params
    );
  }

  getEmployeeTypeWeageSegment(employee_type: number) {
    return this.http.post<any>(
      `${this.baseUrl1}segments/segment/getEmployeeTypeWeageSegment`,
      { employee_type: employee_type }
    );
  }

  addEmployeeSalary(params) {
    console.log(params);
    return this.http.post<any>(
      `${this.baseUrl1}employees/employees/addEmployeeSalary`,
      params
    );
  }

  getEmployeeSalaryDetails(employee_id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}employees/employees/getEmployeeSalaryDetails`,
      { employee_id: employee_id }
    );
  }
  updateEmployeeSalary(params) {
    return this.http.post<any>(
      `${this.baseUrl1}employees/employees/updateEmployeeSalary`,
      params
    );
  }

  getDesignations(employee_type: number) {
    return this.http.post<any>(
      `${this.baseUrl1}designations/designations/designationsList`,
      { employee_type: employee_type }
    );
  }

  addVendor(params) {
    return this.http.post<any>(
      `${this.baseUrl1}vendors/vendors/vendorsRegistration`,
      params
    );
  }

  vendorList(company_id: number) {
    return this.http.post<any>(`${this.baseUrl1}vendors/vendors/vendorsList`, {
      company_id: company_id,
    });
  }

  getVendorsById(id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}vendors/vendors/getVendorsById`,
      { id: id }
    );
  }

  updateVendor(params) {
    return this.http.post<any>(
      `${this.baseUrl1}vendors/vendors/vendorsUpdate`,
      params
    );
  }

  vendorsDelete(id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}vendors/vendors/vendorsDelete`,
      { id: id }
    );
  }

  getVendorTypes() {
    return this.http.get<any>(
      `${this.baseUrl1}company_vendors/company_vendors/vendorsTypeList`
    );
  }

  inventoryItemList() {
    return this.http.get<any>(
      `${this.baseUrl1}inventoryitems/inventoryitems/inventoryitemsList`
    );
  }

  addInventoryItem(params) {
    return this.http.post<any>(
      `${this.baseUrl1}inventoryitems/inventoryitems/addInventoryitems`,
      params
    );
  }

  getItemCategory() {
    return this.http.get<any>(
      `${this.baseUrl1}inventory_item_category/Inventoryitemcategory/inventoryitemcategoryList`
    );
  }
  addInventoryCategory(params) {
    return this.http.post<any>(
      `${this.baseUrl1}inventory_item_category/Inventoryitemcategory/addInverntoryCategory`,
      params
    );
  }

  getItemType() {
    return this.http.get<any>(
      `${this.baseUrl1}inventoryitemtypes/Inventoryitemtypes/inventoryitemtypesList`
    );
  }

  addInventoryType(params) {
    return this.http.post<any>(
      `${this.baseUrl1}inventoryitemtypes/Inventoryitemtypes/addInventoryType`,
      params
    );
  }

  getInventoryName() {
    return this.http.get<any>(
      `${this.baseUrl1}inventoryitems/inventoryitems/inventoryName`
    );
  }

  /********* Weages Segment***********/

  addSegments(params) {
    return this.http.post<any>(
      `${this.baseUrl1}segments/segment/addSegments`,
      params
    );
  }

  getEarningSegments() {
    return this.http.get<any>(
      `${this.baseUrl1}segments/segment/getEarningSegment`
    );
  }

  getDeductionSegments() {
    return this.http.get<any>(
      `${this.baseUrl1}segments/segment/getDeductionSegment`
    );
  }

  addEmployeeTypeWeageSegments(params) {
    return this.http.post<any>(
      `${this.baseUrl1}segments/segment/addEmployeeTypeWeageSegments`,
      params
    );
  }

  /***********Wage Calculation Setup*******************/

  getWeekDays() {
    return this.http.get<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/weekDays`
    );
  }

  getPaymentIntervals() {
    return this.http.get<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/paymentIntervals`
    );
  }

  getRecoveryInterval() {
    return this.http.get<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/recoveryIntervals`
    );
  }
  addWageBasics(params) {
    return this.http.post<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/addWageBasics`,
      params
    );
  }

  addWorkerAgeAndOptionalParameters(params) {
    return this.http.post<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/addWorkerAgeAndOptionalParameters`,
      params
    );
  }

  addPfLoanInterestRangeData(params) {
    return this.http.post<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/addPfLoanInterestRangeData`,
      params
    );
  }

  getWageBasicsByEmployeeType(params) {
    return this.http.post<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/getWageBasicsByEmployeeType`,
      params
    );
  }
  getWorkerAgeData(params) {
    return this.http.post<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/getWorkerAgeData`,
      params
    );
  }
  getOptionalParameterData(params) {
    return this.http.post<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/getOptionalParameterData`,
      params
    );
  }

  addPfPensionAndWfa(params) {
    return this.http.post<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/addPfPensionAndWfa`,
      params
    );
  }

  getWelfareFundData(params) {
    return this.http.post<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/getWelfareFundData`,
      params
    );
  }

  getPfAndPensionData(params) {
    return this.http.post<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/getPfAndPensionData`,
      params
    );
  }

  getPfLoanInterestRangeData(params) {
    return this.http.post<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/getPfLoanInterestRangeData`,
      params
    );
  }

  addRationFoodValueData(params) {
    return this.http.post<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/addRationFoodValueData`,
      params
    );
  }

  getRationFoodValueData(params) {
    return this.http.post<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/getRationFoodValueData`,
      params
    );
  }

  getAllWageBasicsData() {
    return this.http.get<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/getAllWageBasicsData`
    );
  }

  getAllPfPensionData() {
    return this.http.get<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/getAllPfPensionData`
    );
  }
  getAllPfLoanInterestRangeData() {
    return this.http.get<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/getAllPfLoanInterestRangeData`
    );
  }
  getAllgetWorkerAgeData() {
    return this.http.get<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/getAllgetWorkerAgeData`
    );
  }
  getAllWelfareFundData() {
    return this.http.get<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/getAllWelfareFundData`
    );
  }
  getAllRationFoodValueData() {
    return this.http.get<any>(
      `${this.baseUrl1}wages/Wagecalculationsetup/getAllRationFoodValueData`
    );
  }

  /*****************Factory Activity*******************/
  addPacketMaterial(params) {
    return this.http.post<any>(
      `${this.baseUrl1}factory_activity/factory/addPacketMaterial`,
      params
    );
  }

  getAllPackets() {
    return this.http.get<any>(
      `${this.baseUrl1}factory_activity/factory/getAllPackets`
    );
  }

  getPacketsById(packet_id: number) {
    return this.http.post<any>(
      `${this.baseUrl1}factory_activity/factory/getPacketsById`,
      { packet_id: packet_id }
    );
  }

  getTeaType() {
    return this.http.get<any>(
      `${this.baseUrl1}factory_activity/factory/getTeaType`
    );
  }

  getManufactureType() {
    return this.http.get<any>(
      `${this.baseUrl1}factory_activity/factory/getManufactureType`
    );
  }

  addGrades(params) {
    return this.http.post<any>(
      `${this.baseUrl1}factory_activity/factory/addGrades`,
      params
    );
  }

  getGradedTeaStockByGradeId(gradeId: number) {
    return this.http.post<any>(
      `${this.baseUrl1}factory_activity/factory/getGradedTeaStockByGradeId`,
      { gradeId: gradeId }
    );
  }
  getAllGrades() {
    return this.http.get<any>(
      `${this.baseUrl1}factory_activity/factory/getAllGrades`
    );
  }

  getAllGardens(params) {
    return this.http.post<any>(
      `${this.baseUrl1}company/teaestateunit/getUnits`,
      params
    );
  }

  addField(params) {
    return this.http.post<any>(
      `${this.baseUrl1}factory_activity/factory/addField`,
      params
    );
  }

  addPlantField(params) {
    return this.http.post<any>(
      `${this.baseUrl1}factory_activity/factory/addField?flag=trees`,
      params
    );
  }

  getAllFactories(params) {
    return this.http.post<any>(
      `${this.baseUrl1}company/teaestateunit/getUnits`,
      params
    );
  }
  getAllFactoriesList() {
    return this.http.get<any>(
      `${this.baseUrl1}company/teaestateunit/getAllFactory`
    );
  }
  getAllField() {
    return this.http.get<any>(
      `${this.baseUrl1}factory_activity/factory/getAllField`
    );
  }
  getAllPlantField() {
    return this.http.get<any>(
      `${this.baseUrl1}factory_activity/factory/getAllField?flag=trees`
    );
  }
  addLidetails(params) {
    return this.http.post<any>(
      `${this.baseUrl1}factory_activity/factory/addLidetails`,
      params
    );
  }

  getLidetails(params) {
    return this.http.post<any>(
      `${this.baseUrl1}factory_activity/factory/getLidetails`,
      params
    );
  }

  getStock() {
    return this.http.get<any>(
      `${this.baseUrl1}factory_activity/factory/getStock`
    );
  }
  bulkMadeTeaEtry(params) {
    return this.http.post<any>(
      `${this.baseUrl1}factory_activity/factory/bulkMadeTeaEtry`,
      params
    );
  }
  getMadeTeaDetails() {
    return this.http.get<any>(
      `${this.baseUrl1}factory_activity/factory/getMadeTeaDetails`
    );
  }

  addGradedTea(params) {
    return this.http.post<any>(
      `${this.baseUrl1}factory_activity/factory/teaGradetion`,
      params
    );
  }
  getGradedTeaDetails() {
    return this.http.get<any>(
      `${this.baseUrl1}factory_activity/factory/getGradedTeaDetails`
    );
  }
  getBulkTeaStock() {
    return this.http.get<any>(
      `${this.baseUrl1}factory_activity/factory/getBulkTeaStock`
    );
  }

  teaPacking(params) {
    return this.http.post<any>(
      `${this.baseUrl1}factory_activity/factory/teaPacking`,
      params
    );
  }

  getTeaPackingDetails() {
    return this.http.get<any>(
      `${this.baseUrl1}factory_activity/factory/getTeaPackingDetails`
    );
  }

  getGradeTeaStock() {
    return this.http.get<any>(
      `${this.baseUrl1}factory_activity/factory/getGradeTeaStock`
    );
  }

  /***************************************************************************/ ///

  getRoles() {
    return this.http.get<any>(`${this.baseUrl1}users/users/roles`);
  }

  getCountries() {
    return this.http.get<any>(`${this.baseUrl1}users/users/countries`);
  }

  getStates(country_id: number) {
    return this.http.post<any>(`${this.baseUrl1}users/users/states`, {
      country_id: country_id,
    });
  }

  getCities(state_id: number) {
    return this.http.post<any>(`${this.baseUrl1}users/users/cities`, {
      state_id: state_id,
    });
  }

  logout() {
    localStorage.removeItem("currentUser");
  }

  isLoggedIn() {
    if (localStorage.getItem("currentUser")) {
      return true;
    }
    return false;
  }

  /************************************************
  Pagination  functionalty
 ********************************************/

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }
  getEmployeeData() {
    return this.http.get<any>(
      `${this.baseUrl1}employees/employees/getAllEmpData`
    );
  }
  massUpdateEmpAttendance(data) {
    return this.http.post<any>(
      `${this.baseUrl1}employees/employees/massUpdateEmpAttendance`,
      data
    );
  }
  addEmpAttendance(data) {
    return this.http.post<any>(
      `${this.baseUrl1}employees/employees/addEmpAttendance`,
      data
    );
  }
  getSearchData(data) {
    return this.http.post<any>(
      `${this.baseUrl1}employees/employees/searchAttendanceData`,
      data
    );
  }
  getAllPacketMaterialData() {
    return this.http.get<any>(
      `${this.baseUrl1}factory_activity/factory/getAllPacketMaterialData`
    );
  }
  getSinglePack(id) {
    return this.http.post<any>(
      `${this.baseUrl1}factory_activity/factory/getSinglePacketData`,
      id
    );
  }
  insertImportData(data) {
    return this.http.post<any>(
      `${this.baseUrl1}factory_activity/factory/insertImportData`,
      data
    );
  }

  getImportedList() {
    return this.http.get<any>(
      `${this.baseUrl1}factory_activity/factory/getImportedList`
    );
  }
  getSingleImportedData(id) {
    return this.http.post<any>(
      `${this.baseUrl1}factory_activity/factory/getSingleImportedData`,
      id
    );
  }
  updateImportData(data) {
    return this.http.post<any>(
      `${this.baseUrl1}factory_activity/factory/updateImportedData`,
      data
    );
  }

  addExportTeaRecord(data) {
    return this.http.post<any>(
      `${this.baseUrl1}factory_activity/factory/addExportTeaData`,
      data
    );
  }
  getAllExportRecords() {
    return this.http.get<any>(
      `${this.baseUrl1}factory_activity/factory/getAllExportRecords`
    );
  }
  approveExport(id) {
    return this.http.post<any>(
      `${this.baseUrl1}factory_activity/factory/approveExport`,
      id
    );
  }
  //GreenTea Module
  addSellTeaRecord(data) {
    return this.http.post<any>(
      `${this.baseUrl1}factory_activity/factory/addSellTeaRecord`,
      data
    );
  }

  getAllGreenTeaList() {
    return this.http.get<any>(
      `${this.baseUrl1}factory_activity/factory/getAllGreenTeaList`
    );
  }
  getGreenTeaSellDetails(data) {
    return this.http.post<any>(
      `${this.baseUrl1}factory_activity/factory/getGreenTeaSellDetails`,
      data
    );
  }

  // Billing Section Starts
  addNewBill(data) {
    return this.http.post<any>(
      `${this.baseUrl1}billing/billing/addNewBill`,
      data
    );
  }

  getBills() {
    return this.http.get<any>(`${this.baseUrl1}billing/billing/getBills`);
  }
  getBillDetails(id) {
    return this.http.post<any>(
      `${this.baseUrl1}billing/billing/getBillDetails`,
      id
    );
  }

  //Bill Payment
  billPaymentRecord(data) {
    return this.http.post<any>(
      `${this.baseUrl1}billing/billing/billPaymentRecord`,
      data
    );
  }

  //invoice
  generateInvoice(data) {
    return this.http.post<any>(
      `${this.baseUrl1}invoice/invoice/generateInvoice`,
      data
    );
  }

  addBankAccount(data) {
    return this.http.post<any>(
      `${this.baseUrl1}accounting/accounting/addNewBankAccount`,
      data
    );
  }

  getAccounts() {
    return this.http.get<any>(
      `${this.baseUrl1}accounting/accounting/getAllAccounts`
    );
  }
}
