import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import{AdminService} from '../admin.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private adminservice: AdminService,public router:Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(error=> {
        if (error instanceof HttpErrorResponse) {
            if (error.error instanceof ErrorEvent) {
                console.error("Error Event");
            } else {
                console.log(`error status : ${error.status} ${error.statusText}`);
                switch (error.status) {
                    case 401:      //login
                        alert('Your Session Is Expire Please Login Again');
                        this.adminservice.logout();
                        this.router.navigateByUrl("/login");
                        break;
                    case 403:     //forbidden
                        this.router.navigateByUrl("/login");
                        break;
                }
            } 
        } else {
            console.error("some thing else happened");
        }
        return throwError(error);
        }))
    }
}