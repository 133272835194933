import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import{AdminService} from '../admin.service';
import {User} from '../user';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	username:string;
	password:string;
  teaestate_id:number;
  company_id:number;
  errorMessage:any;
  companies:any;
  teaestates:any;
  user= new User();
  constructor(private adminservice: AdminService, private router:Router) { 

  	 // redirect to home if already logged in
        if (this.adminservice.currentUserValue) { 
            this.router.navigate(['/dashboard']);
        }
  }

  ngOnInit() {
   
  }


 

 

  submit(){
	    this.adminservice.login(this.user).subscribe((response) => {
        this.router.navigate(['/dashboard']);
       },
       (error)=>{
        console.log(error)
          this.errorMessage=error.error.message;
       })
	}
}


